import styled from "styled-components";

export const DesktopView = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileView = styled.div`
  @media screen and (min-width: 769px) {
    display: none;
  }
`;