import styled from "styled-components";

const PrimaryButton = styled.button`
  min-width: 16rem;
  border-radius: 5px;
  border: 2px solid ${props => props.theme.textWhite};
  background-color: transparent;
  color: ${props => props.theme.textWhite};
  font-size: 1.5rem;
  padding: 0.5rem;
  box-shadow: 1px 9px 35px -1px rgba(0,0,0,1);
  text-shadow: 1px 1px 10px rgba(0,0,0,1);

  @media (hover: hover) {
    &:hover {
      border: 2px solid ${props => props.theme.textGold};
      color: ${props => props.theme.textGold};
      background-color: rgba(0,0,0, .5);

    }
  }
`;

const DarkButton = styled.button`
  min-width: 16rem;
  border-radius: 10px;
  border: 2px solid ${props => props.theme.textWhite};
  background-color: ${props => props.theme.bgGrey};
  color: ${props => props.theme.textWhite};
  font-size: 1.5rem;
  padding: 0.5rem;

  @media (hover: hover) {
    &:hover {
        color: ${props => props.theme.textGold};
    }
  }
`;

const SideBarButton = styled.button`
  color: ${props => props.theme.textWhite};
  background: none;
  border: none;

  @media (hover: hover) {
    &:hover {
        color: ${props => props.theme.textGold};
    }
  }
`;

export { PrimaryButton, DarkButton, SideBarButton };