import * as React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { TileRow } from "../../components";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { DesktopView } from "../../components/layout-helpers";
import { MobileView } from "../../components/layout-helpers";
import { DarkButton } from "../../components/buttons";
import servicesImg from "../../images/services.jpg";

const content = {
  services: {
    title: "Concrete Services",
    image: servicesImg,
    buttonText: "Request Estimate",
    to: "/estimate",
  },
  seo: {
    siteUrl: "https://hconcoinc.com/services/concrete",
    title: "Services",
    keywords: [
      `concrete paving`,
      `masonry brick and block`,
      `concrete`,
      `hconco`,
      `nashville`,
    ],
    description:
      "Some of our services include concrete paving, masonry brick and block, foundations, and tilt up construction",
  },
};

const services = [
  "CIP Walls",
  "Columns",
  "Concrete Demo",
  "Concrete Paving",
  "Concrete saw cutting",
  "Continuous and Spread Footings",
  "Culvert Installation",
  "Curb & Gutter",
  "Driveways",
  "Elevator Shafts",
  "Epoxy & Dowel installation",
  "Excavation",
  "Grade beams",
  "Grading",
  "Gravel delivery",
  "Masonry Brick and Block",
  "Mat Foundation slabs",
  "Pervious Concrete",
  "Post Tension Slabs",
  "Precast concrete",
  "Retaining walls",
  "Shear Walls",
  "Sidewalks (ADA)",
  "Slab on Grade",
  "Slab on Metal Deck",
  "Spoil haul off",
  "Tilt Up Construction",
];

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const FlexItem = styled.div`
  flex: 50%;
  white-space: nowrap;
`;

const ServicesContainer = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const StyledDesktopView = styled(DesktopView)`
  max-width: 90%;
`;

const StyledMobileView = styled(MobileView)`
  margin-bottom: 4rem;
`;

const FlexContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const FlexItemMobile = styled.div`
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
`;

const HeadlineText = styled.h1`
  color: ${(props) => props.theme.textGrey};
  font-size: 2rem;
  margin: 1rem 0 1rem 0;

  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const BodyText = styled.div`
  color: ${(props) => props.theme.textGrey};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 4rem;
`;

const Section = styled.div`
  width: 80%;
  min-height: 500px;
  margin: auto;

  @media screen and (min-width: 768px) {
    width: 75%;
  }
`;

const ImageSection: any = styled(Section)`
  background-image: ${(props: any) => `url(${props.background})`};
  width: 100%;
  min-height: 400px;
  width: 100%;
  background-size: cover;
`;

const StyledDisclaimer = styled.div`
  text-align: center;
  font-size: 0.8rem;
  margin: 0.5rem 0 0.5rem 0;
  color: ${(props) => props.theme.disclaimerGrey};
`;

const Description = styled.p`
    margin-bottom: 3rem;
`;

const disclaimer = `*Payments for all services must be paid by agreed upon date in the work contract. Work is subject to demolition if payment terms are not met.`;
const descriptionText = `We offer a wide variety of residential and commercial concrete services. No job is too big or too small.`;
const ServicesHeading = styled.div`
font-weight: bold;
font-size: 1.5rem;
margin-bottom: 1rem;
`;
const ConcretePage = () => (
  <Layout>
    <SEO
      title={content.seo.title}
      keywords={content.seo.keywords}
      description={content.seo.description}
      siteUrl={content.seo.siteUrl}
    />
    <ServicesContainer>
      <StyledDesktopView>
          
        <TileRow
          title={content.services.title}
          buttonText={content.services.buttonText}
          image={content.services.image}
          buttonOnClick={() => navigate(content.services.to)}
        >
            <Description>
             {descriptionText} 
          </Description>
          <FlexContainer>
            <FlexItem>
            <ServicesHeading>Services:</ServicesHeading>

              {services.map((s, i) => (
                <span key={i}>
                  {s}
                  <br />
                </span>
              ))}
            </FlexItem>
          </FlexContainer>
        </TileRow>
      </StyledDesktopView>
    </ServicesContainer>

    <StyledMobileView>
      <ImageSection background={servicesImg} />
      <Section>
        <FlexContainerColumn>
          <FlexItemMobile>
            <HeadlineText>{content.services.title}</HeadlineText>
          </FlexItemMobile>
          <Description>
          {descriptionText}  
          </Description>
          <FlexItemMobile>
            <BodyText>
            <ServicesHeading>Services:</ServicesHeading>

              {services.map((s, i) => (
                <span key={i}>
                  {s}
                  <br />
                </span>
              ))}
            </BodyText>
          </FlexItemMobile>
          <FlexItemMobile>
            <DarkButton onClick={() => navigate(content.services.to)}>
              {content.services.buttonText}
            </DarkButton>
          </FlexItemMobile>
        </FlexContainerColumn>
      </Section>
    </StyledMobileView>
    <StyledDisclaimer>{disclaimer}</StyledDisclaimer>
  </Layout>
);

export default ConcretePage;
