import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { DarkButton } from "./buttons";
import { navigate } from "gatsby";
import flag from "../images/bwflag_spaced_gold.jpg";
import { breakpoints } from "../constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  align-items: center;
`;

const Title = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.textGrey};

  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }
`;

const Flag = styled.img`
  width: 70%;
  max-width: 35rem;
  margin-bottom: 3rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 3rem;
    min-width: 360px;
  }
`;

const ServicesRow = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    width: 75%;
    justify-content: center;
  }

  @media screen and (min-width: 1152px) {
    width: 65%;
  }

  @media screen and (min-width: 1330px) {
    width: 95%;
    flex-wrap: no-wrap;
  }
`;

const ServicesCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;


const ImageContainer = styled.div`
 width: 100%;
  height: 10rem;
  margin-left: 2rem;
  margin-right: 2rem;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
overflow: hidden;
  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    width: 12rem;
    height: 12rem;
  }

  @media (min-width: ${breakpoints.tabletLandscape}px) {
    width: 14rem;
    height: 14rem;
  }
`;


const SquareImage = styled.img<{customStyles: string}>`
 width: 100%;
 height: 100%;
  object-fit: cover;
 
  
  ${(props: {customStyles: string}) => `${props.customStyles}`}
 
`;



const Text = styled.div`
  padding-top: 1rem;
  font-size: 1.5rem;
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 1rem;
  }
`;

export interface ImageOption {
  src: string;
  subTitle: string;
  to: string;
  buttonText: string;
  customStyles?: string;
}
interface ThreeImageSectionProps {
  title: string;
  imageOptions: ImageOption[];
}

const ImageButton = styled(DarkButton)`
  font-size: 0.9rem;
  max-width: 12rem;
  text-align: center;
  margin-top: 1rem;
  min-width: 12rem;
`;

export const ThreeImageSection = ({
  title,
  imageOptions,
}: ThreeImageSectionProps) => {
  const handleButtonClick = useCallback((to: string) => {
    navigate(to);
  }, []);
  return (
    <Container>
      <Title>{title}</Title>
      <Flag
        src={flag}
        alt={"American flag with concrete services listed on the stripes"}
      ></Flag>

      <Title>Other specialty services</Title>

      <ServicesRow>
        {imageOptions?.map((option) => (
          <ServicesCol key={option.subTitle}>
            <Text>{option.subTitle}</Text>

            <ImageContainer>
              <SquareImage
                onClick={() => handleButtonClick(option.to)}
                src={option.src}
                alt={option.subTitle}
                customStyles={option.customStyles ?? ''}
              />
            </ImageContainer>

            <ImageButton onClick={() => handleButtonClick(option.to)}>
              {option.buttonText}
            </ImageButton>
          </ServicesCol>
        ))}
      </ServicesRow>
    </Container>
  );
};
