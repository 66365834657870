import * as React from "react"
import styled from "styled-components";
import TileContent from "./Tile";
import TileImage from "./TileImage";

const FlexRow = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`;

const Tile: any = styled.div`
  flex: 50%;
  max-width: 450px;
  ${(props: any) => props.imagePlacement === 'left' ? 'order: 1': 'order: 0'};
`;

interface TileRowProps {
  title: string;
  children: JSX.Element;
  buttonText: string;
  image: string;
  imagePlacement?: string;
  buttonEnabled?: boolean;
  buttonOnClick: () => void;
  imageFit?: string;
}
const TileRow = ({title, children, buttonText, image, imagePlacement = 'right', buttonEnabled = true, buttonOnClick, imageFit}: TileRowProps) => (
    <FlexRow>
      <Tile imagePlacement={imagePlacement}>
        <TileContent title={title} buttonText={buttonText} buttonEnabled={buttonEnabled} buttonOnClick={buttonOnClick}>
            {children}
        </TileContent>
      </Tile>
      <Tile>
        <TileImage src={image} imageFit={imageFit} />
      </Tile>
    </FlexRow>
)

export default TileRow;