import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;

    @media screen and (max-width: 768px) {
        flex-direction: column;
      }
`;

const ImageColumn: any = styled.div`
    flex: 40%;
    background-image: url(${(props: any) => props.background});
    background-size: cover;
    @media screen and (max-width: 768px) {
        height: 350px;
    }

    @media screen and (min-width: 768px) {
        min-height: 100vh;
    }
`;

const Column = styled.div`
    flex: 60%;
    padding: 5rem 1rem 1rem 1rem;
`;

type Props = {
    background: any;
    children?: any;
}

const TwoColumnContainer = ({ background, children }: Props) => {
    return (
        <Container>
            <ImageColumn background={background}></ImageColumn>
            <Column>
                {children}
            </Column>
        </Container>
    )
}

export default TwoColumnContainer;