import * as React from 'react';
import styled from "styled-components";
import { DarkButton } from './buttons';

const TileContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 80%;
  /* padding-left: 3rem; */
  padding-top: 5rem;
    padding-bottom: 8rem;
`;

const Title = styled.h1`
    font-size: 2rem;
    font-weight: bold;
`;

const Line = styled.hr``;

const Content = styled.div`
    line-height: 1.5rem;
    padding-bottom: 5rem;
`;

const Button = styled(DarkButton)`
    max-width: 5rem;
`;

interface TileProps {
    title: string;
    children?: any;
    buttonText: string;
    buttonOnClick: any;
    buttonEnabled?: boolean;
}



const TileContent = ({ title, buttonText, children, buttonOnClick, buttonEnabled = true}: TileProps) => {
    return (
        <TileContainer>
            <Title>{title}</Title>
            {/* <Line /> */}
            <Content>
                {children}
            </Content>
           {buttonEnabled && <Button onClick={buttonOnClick}>{buttonText}</Button>}
        </TileContainer>
    );
}

export default TileContent;